<div class="layout-wrapper" [ngClass]="containerClass">
  <app-topbar (menuclicked)="menuClicked($event)" [locales]="languages"></app-topbar>
  <div class="layout-sidebar">
    <app-sidebar class="app-sidebar"></app-sidebar>
  </div>
  <p-button
    class="sidebar-collapsible"
    [icon]="collapsibleClass"
    [rounded]="true"
    [text]="true"
    [raised]="true"
    styleClass="white-bg collapsible-icon"
    (click)="layoutService.onMenuToggle()"
  ></p-button>
  <div class="layout-main-container">
    <div class="layout-main">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div class="layout-mask"></div>
</div>
<p-confirmDialog
  #cd
  header="{{ 'confirmation' | translate }}"
  icon="pi pi-exclamation-triangle"
  [style]="{ width: '40vw' }"
>
  <ng-template pTemplate="footer">
    <p-button
      [text]="true"
      [outlined]="true"
      label="{{ 'no' | translate }}"
      icon="pi pi-times"
      class="mr-4"
      (onClick)="cd.reject()"
    ></p-button>
    <p-button icon="pi pi-check" label="{{ 'yes' | translate }}" (onClick)="cd.accept()"></p-button> </ng-template
></p-confirmDialog>
<p-toast position="top-center"></p-toast>
<p-toast position="top-right" key="tr" />

<p-dialog
  header="{{ 'defaultLanguage' | translate }}"
  [modal]="true"
  [(visible)]="dialogDefaultLanguage"
  [style]="{ width: '25rem' }"
>
  <form [formGroup]="languageForm" (ngSubmit)="saveLanguage()">
    <span class="p-text-secondary block mb-4">{{ 'select' | translate }} {{ 'defaultLanguage' | translate }}</span>
    <div class="flex align-items-center gap-3 mb-5">
      <label for="language" class="font-semibold w-6rem">{{ 'language' | translate }}</label>
      <p-dropdown
        id="defaultLanguage"
        formControlName="defaultLanguage"
        [options]="languages"
        [placeholder]="'selectOne' | translate"
        [showClear]="true"
        optionLabel="name"
        optionValue="iso"
        appendTo="body"
      >
        <ng-template pTemplate="item" let-language> {{ language.name }} ({{ language.endonym }}) </ng-template>
        <ng-template pTemplate="selectedItem" let-language> {{ language.endonym }}</ng-template>
      </p-dropdown>
    </div>
    <div class="flex justify-content-end gap-2">
      <p-button
        [text]="true"
        [outlined]="true"
        label="{{ 'cancel' | translate }}"
        icon="pi pi-times"
        class="mr-4"
        (onClick)="dialogDefaultLanguage = false"
      />
      <p-button
        (onClick)="dialogDefaultLanguage = false"
        type="submit"
        icon="pi pi-file"
        [label]="'save' | translate"
        [disabled]="languageForm.invalid"
      />
    </div>
  </form>
</p-dialog>
